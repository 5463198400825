import { BrandTypeFromFirebase } from './brand';

export type EventTypeFromKaik = {
  id: string;
  name: string;
  startedAt: number;
  endedAt: number;
  totalRevenue: number;
  description: string;
  coverPhoto: string;
  attendees: {
    name: string;
    email: string;
    paymentId: string;
  }[];
  attendeesCount: number;
};

/**
 * events/${eventId}
 */
export type EventTypeFromFirebaseEvents = {
  eventType: EventCategory;
  announcement: string;
  currentAgendaIndex?: number;
  source?: {
    resourceId: string;
    resourceName: string;
    isLive: boolean;
  };
};

/**
 * users/${uid}/events
 */
export type EventTypeFromFirebaseUsers = {
  channelUrl: string;
  notice?: string;
  streamService?: 'zoom' | 'webex' | 'obs';
  tickets: TicketType[];
  ticketingStatus?: 'active' | 'inactive-after-started' | 'inactive';
  host: {
    name: string;
    profilePic: string;
    profileLink: string;
  };
  agendas: AgendaType[];
  deletedAt?: number;
  systemRedeemCode: {
    enabled: boolean;
    redeemCode?: string;
  };
};

export type EventTypeFromFirebase = EventTypeFromFirebaseUsers &
  EventTypeFromFirebaseEvents & {
    brand?: {
      slug: BrandTypeFromFirebase['slug'];
      name: BrandTypeFromFirebase['brandName'];
      logoUrl: BrandTypeFromFirebase['logoUrl'];
    };
  } & {
    stripe?: {
      accountId: string;
    };
  };

export enum EventCategory {
  Native = 'native',
  Broadcast = 'broadcast',
  InstantNative = 'instant-native',
}

export type TicketType = {
  id?: string;
  name: string;
  price: number | string;
  currency: string;
  quantity?: number;

  // firebase additional field
  groupPrice: number | string;
  groupAttendeeNumber: number | string;
};

export type AgendaType = {
  id?: string;
  title: string;
  durationInMins: number;
};

export type EventType = EventTypeFromKaik & EventTypeFromFirebase;
