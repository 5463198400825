import {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { Permission } from '@lib/types';

const AudioVideoPermissionContext =
  createContext<UseAudioVideoPermissionReturnType>({
    permission: Permission.Checking,
    handleGetUserMedia: () => {},
    isSupportEnumerateDevices: true,
  });

export type UseAudioVideoPermissionReturnType = {
  permission: Permission;
  handleGetUserMedia: () => void;
  isSupportEnumerateDevices: boolean;
};

function getIsSupportEnumerateDevices() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  return !!navigator?.mediaDevices?.enumerateDevices;
}

function AudioVideoPermissionProvider({ children }: { children: JSX.Element }) {
  const [permission, setPermission] = useState<Permission>(Permission.Checking);
  const [isSupportEnumerateDevices, setIsSupportEnumerateDevices] = useState(
    getIsSupportEnumerateDevices()
  );

  const handleGetUserMedia = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioInput = devices.some((value) => {
        return value.kind === 'audioinput';
      });
      const hasVideoInput = devices.some((value) => {
        return value.kind === 'videoinput';
      });

      // When call getUserMedia with both audio and video are false will cause error
      // Directly take no audio and no video as not support enumerate devices
      if (!hasAudioInput && !hasVideoInput) {
        setIsSupportEnumerateDevices(false);
        return;
      }

      try {
        await navigator.mediaDevices.getUserMedia({
          audio: hasAudioInput,
          video: hasVideoInput,
        });
        setPermission(Permission.Granted);
      } catch (err) {
        setPermission(Permission.Denied);
      }
    } catch (err) {
      setIsSupportEnumerateDevices(false);
      throw err;
    }
  }, []);

  const checkIfDeviceHasGranted = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const undefinedAudioInput = devices.find(
        (device) =>
          device.kind === 'audioinput' &&
          device.label === '' &&
          device.deviceId === ''
      );
      const undefinedVideoInput = devices.find(
        (device) =>
          device.kind === 'videoinput' &&
          device.label === '' &&
          device.deviceId === ''
      );

      if (undefinedAudioInput || undefinedVideoInput) {
        setPermission(Permission.Unset);
        return;
      }
      setPermission(Permission.Granted);
    } catch (err) {
      setIsSupportEnumerateDevices(false);
    }
  }, []);

  useEffect(() => {
    checkIfDeviceHasGranted();
  }, [checkIfDeviceHasGranted]);

  return (
    <AudioVideoPermissionContext.Provider
      value={{ permission, isSupportEnumerateDevices, handleGetUserMedia }}
    >
      {children}
    </AudioVideoPermissionContext.Provider>
  );
}

export const useAudioVideoPermission = () => {
  const audioVideoPermission = useContext(AudioVideoPermissionContext);
  return audioVideoPermission;
};

export default AudioVideoPermissionProvider;
