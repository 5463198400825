import { BellIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Banner from '@components/Banner';
import mixpanel from '@lib/mixpanel';
import { EventTypeFromKaik } from '@lib/types';
import { asyncPost } from '@lib/fetch';
import { useAccountContext } from '@lib/hooks';

const OngoingEventBanner = () => {
  const { t } = useTranslation(['event']);
  const { uid, idToken } = useAccountContext();

  const [ongoingEvent, setOngoingEvent] =
    useState<Partial<EventTypeFromKaik>>();
  const router = useRouter();

  const getOngoingEvents = useCallback(async () => {
    if (!idToken) {
      return;
    }

    const result = (await asyncPost({
      url: `/api/query/getOngoingEventsWithToken`,
      headers: {
        id_token: idToken,
      },
      body: {},
    })) as {
      events: Partial<EventTypeFromKaik>[];
    };

    if (result?.events?.length > 0) {
      // Only set 1 ongoing event even there are more than 1 ongoing event
      setOngoingEvent(result.events[0]);
    }
  }, [idToken]);

  useEffect(() => {
    getOngoingEvents();
  }, [getOngoingEvents]);

  if (!ongoingEvent) {
    return null;
  }

  return (
    <Banner
      wrapperProps={{ p: '0', shadow: 'md' }}
      icon={<Icon as={BellIcon} fontSize="2xl" h="10" />}
      title={t('event:The event you signed up for is ongoing {{eventName}}', {
        eventName: ongoingEvent.name,
      })}
      text={t('event:Enter Room')}
      onClick={() => {
        mixpanel.track('btn_click', {
          uid,
          from: window.location.pathname,
          type: 'ongoing_event_banner',
        });

        router.push(`/s/${ongoingEvent.id}`);
      }}
    />
  );
};

export default OngoingEventBanner;
