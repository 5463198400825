import { Box, Button, Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Layout from '../layouts/LegacyEventLayout';

function Error() {
  const { t } = useTranslation(['common']);

  return (
    <Layout>
      <Box height="100%" width="full">
        <Center flexDirection="column" mt="8">
          <Text my={8} mx={4}>
            {t('common:Something went wrong')}
          </Text>
          <Button as="a" colorScheme="brand" href="/">
            {t('Go back home')}
          </Button>
        </Center>
      </Box>
    </Layout>
  );
}

export default Error;
