export default {
  titleTemplate: '%s - Nutshell Live',
  defaultTitle: 'Nutshell Live',
  facebook: { appId: '953211272080436' },
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://nutshell.live/',
    site_name: 'Nutshell Live',
    images: [
      {
        url: 'https://nutshell.live/nutshell-live-og.jpeg',
        width: 1200,
        height: 628,
        alt: 'Nutshell Live',
      },
    ],
  },
  twitter: {
    handle: '@kaikhq',
    site: '@kaikhq',
    cardType: 'summary_large_image',
  },
};
