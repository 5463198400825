import Head from 'next/head';
import {
  Box,
  Divider,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FC } from 'react';
import { FaHeart } from 'react-icons/fa';

import Navbar from '../components/Navbar';
import { SocialMediaLinks } from '../components/Footer/SocialMediaLinks';

import { LayoutProps } from './Layout';

const Heart = () => (
  <Box
    display="inline-block"
    mx="1"
    color={mode('red.500', 'red.300')}
    fontSize="xs"
    role="img"
    aria-label="Love"
    as={FaHeart}
  />
);

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#5bbad5" />
      </Head>
      <Navbar />
      {children}
      <Box
        as="footer"
        role="contentinfo"
        mx="auto"
        maxW="6xl"
        py="12"
        px={{ base: '4', md: '8' }}
      >
        <Divider />
        <Stack
          spacing="10"
          mt="2"
          direction={{ base: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Text mt="2">
              &copy; {new Date().getFullYear()} Made with <Heart /> by{' '}
              <a
                href="https://kaik.com/?ref=live"
                target="_blank"
                rel="noreferrer"
              >
                Kaik, Inc.
              </a>
            </Text>
          </Box>
          <SocialMediaLinks />
        </Stack>
      </Box>
    </>
  );
};

export default Layout;
