import NextLink from 'next/link';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { BiCog, BiLogOut } from 'react-icons/bi';
import { Fragment, useEffect, useState } from 'react';
import { RiLiveLine } from 'react-icons/ri';
import { useTranslation } from 'next-i18next';

import { useAccountContext } from '@lib/hooks';

import OngoingEventBanner from '../OngoingEventBanner';

import { NavLink } from './NavLink';

export const NavBarMenu = () => {
  const { uid, user } = useAccountContext();
  const { t } = useTranslation(['navbar', 'auth']);
  const [loginPath, setLoginPath] = useState('/login');

  const renderMenuListContent = () => {
    const menus = [
      {
        href: '/admin/events',
        text: t('navbar:Events'),
        icon: <RiLiveLine />,
      },
      {
        href: '/admin/settings',
        text: t('navbar:Account Settings'),
        icon: <BiCog />,
      },
      { component: <MenuDivider /> },
      {
        href: '/logout',
        text: t('navbar:Log out'),
        icon: <BiLogOut />,
      },
    ];

    return menus.map(({ href, text, icon, component }, index) => {
      if (component) {
        return <Fragment key={index}>{component}</Fragment>;
      }

      return (
        <NextLink href={href as string} key={href}>
          <MenuItem icon={icon}>{text}</MenuItem>
        </NextLink>
      );
    });
  };

  useEffect(() => {
    setLoginPath(`/login?redirect=${window.location.pathname}`);
  }, []);

  return uid ? (
    <Menu>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
      >
        <Avatar size={'sm'} name={user.name} src={user.profilePic} />
      </MenuButton>
      {/* See issue https://github.com/chakra-ui/chakra-ui/issues/4109 */}
      <MenuList zIndex={999} rootProps={{ style: { right: 0 } }}>
        {renderMenuListContent()}
      </MenuList>
    </Menu>
  ) : (
    <NavLink.Desktop href={loginPath}>{t('auth:Login')}</NavLink.Desktop>
  );
};

export const NavBarLogo = () => {
  return (
    <NextLink href="/" passHref>
      <Box as="a" rel="home">
        <strong>Nutshell Live</strong>
      </Box>
    </NextLink>
  );
};

function NavBar() {
  return (
    <>
      <Box as="header" bg={mode('white', 'gray.800')} borderBottomWidth="1px">
        <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
          <Flex as="nav" justify="space-between">
            <HStack spacing="8">
              <NavBarLogo />
            </HStack>

            <Flex align="center">
              <NavBarMenu />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <OngoingEventBanner />
    </>
  );
}

export default NavBar;
