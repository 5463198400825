import MixpanelBrowser, { Mixpanel } from 'mixpanel-browser';

const isProduction = process.env.NODE_ENV === 'production';

// dummy instance for dev usage
let mixpanel = {
  track: (...args: any[]) => {
    console.log('track event caught!');
    console.log(args);
  },
  identify: (...args: any[]) => {
    console.log('identify event caught!');
    console.log(args);
  },
} as unknown as Mixpanel;

if (isProduction) {
  // make sure the production env has correct mixpanel token
  if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
    throw new Error('Mixpanel token not provided');
  }
  mixpanel = MixpanelBrowser.init(
    process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
    { debug: !isProduction },
    'kaik-live-web'
  );
}

export default mixpanel;
