import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector(null, {
  order: ['navigator'],
});

let loadPathUrl;

if (process.env.NODE_ENV === 'development') {
  loadPathUrl = 'http://localhost:3000';
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
  loadPathUrl = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  loadPathUrl = 'https://nutshell.live';
}

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    debug: false,
    ns: [
      'common',
      'admin',
      'breadcrumb',
      'auth',
      'event',
      'footer',
      'homepage',
      'navbar',
      'stream',
    ],
    load: 'currentOnly',
    defaultNS: 'common',
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${loadPathUrl}/locales/{{lng}}/{{ns}}.json`,
    },
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: '.',
    react: {
      useSuspense: false,
    },
  });

export default i18n;
