export type RedeemCodeType = {
  id: string;
  eventId: string;
  email?: string;
  ticketId?: string;
  transactionId?: string;
};

export enum RedeemCodeEnum {
  Purchased = 'purchased',
  Vip = 'vip',
  Customized = 'customized',
}
