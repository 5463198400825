// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://a76ccfce0a76469eb333ec247b2a4687@o234807.ingest.sentry.io/5787402',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    // Ignore Safari and Chrome browser errors
    // https://stackoverflow.com/questions/63653605/resizeobserver-loop-limit-exceeded-api-is-never-used
    // https://stackoverflow.com/questions/65732703/resizeobserver-loop-limit-exceeded-stop-exception
    'ResizeObserver loop limit exceeded',

    // Ignore Google translation and React.fragment issue
    // https://github.com/facebook/react/issues/11538#issuecomment-390386520
    `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
  ],
});
