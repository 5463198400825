import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styleToString = (style: any) => {
  return Object.keys(style).reduce(
    (acc, key) =>
      acc +
      key
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase() +
      ':' +
      style[key] +
      ';',
    ''
  );
};

export const useScript = (
  url: string,
  shouldSkipFunc: { (): boolean; (): any }
) => {
  useEffect(() => {
    if (shouldSkipFunc()) return;

    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);
  }, [shouldSkipFunc, url]);
};

function useTappay({
  appId,
  appKey,
  onTransactionSuccess,
  onTransactionError,
  style,
}: {
  onTransactionSuccess: (prime: string) => void;
  onTransactionError: (error: string) => void;
  appId: string;
  appKey: string;
  style?: {
    tpfield: CSSProperties;
    tplabel: CSSProperties;
  };
}): {
  tappayEl: ReactNode;
  onSubmit: () => void;
  canGetPrime: boolean;
} {
  const { t } = useTranslation();
  const [canGetPrime, setCanGetPrime] = useState(false);

  const handleSDKSetup = () => {
    if (!window.TPDirect) return;
    window.TPDirect.setupSDK(
      appId,
      appKey,
      process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
    );
  };
  const handleCardSetup = () => {
    window.TPDirect.card.setup({
      fields: {
        number: {
          // css selector
          element: '#card-number',
          placeholder: '**** **** **** ****',
        },
        expirationDate: {
          // DOM object
          element: document.getElementById('card-expiration-date'),
          placeholder: 'MM / YY',
        },
        ccv: {
          element: '#card-ccv',
          placeholder: 'ccv',
        },
      },
      styles: {
        // Style all elements
        input: {
          color: 'gray',
        },
        // Styling ccv field
        'input.ccv': {
          'font-size': '1em',
        },
        // Styling expiration-date field
        'input.expiration-date': {
          'font-size': '1em',
        },
        // Styling card-number field
        'input.card-number': {
          'font-size': '1em',
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          color: 'green',
        },
        // style invalid state
        '.invalid': {
          color: 'red',
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          input: {
            color: '#222',
          },
        },
      },
    });
  };

  const handleUpdateListener = () => {
    window.TPDirect.card.onUpdate(function (update: {
      canGetPrime: any;
      cardType: string;
      status: { number: number; expiry: number; ccv: number };
    }) {
      // update.canGetPrime === true
      // --> you can call TPDirect.card.getPrime()
      if (update.canGetPrime) {
        setCanGetPrime(true);
      } else {
        setCanGetPrime(false);
      }

      // cardTypes = ['mastercard', 'visa', 'jcb', 'amex', 'unionpay','unknown']
      if (update.cardType === 'visa') {
        // Handle card type visa.
      }

      // number 欄位是錯誤的
      if (update.status.number === 2) {
        // setNumberFormGroupToError()
      } else if (update.status.number === 0) {
        // setNumberFormGroupToSuccess()
      } else {
        // setNumberFormGroupToNormal()
      }

      if (update.status.expiry === 2) {
        // setNumberFormGroupToError()
      } else if (update.status.expiry === 0) {
        // setNumberFormGroupToSuccess()
      } else {
        // setNumberFormGroupToNormal()
      }

      if (update.status.ccv === 2) {
        // setNumberFormGroupToError()
      } else if (update.status.ccv === 0) {
        // setNumberFormGroupToSuccess()
      } else {
        // setNumberFormGroupToNormal()
      }
    });
  };

  const onSubmit = () => {
    if (window.TPDirect) {
      const { TPDirect } = window;
      // 取得 TapPay Fields 的 status
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();

      // 確認是否可以 getPrime
      if (tappayStatus.canGetPrime === false) {
        onTransactionError(t('Tappay error'));
        return;
      }

      // Get prime
      TPDirect.card.getPrime(
        (result: { status: number; msg: any; card: { prime: string } }) => {
          if (result.status !== 0) {
            onTransactionError(`${t('Tappay error')}: ${result.msg}`);
            return;
          }

          onTransactionSuccess(result.card.prime);
          // send prime to your server, to pay with Pay by Prime API .
          // Pay By Prime Docs: https://docs.tappaysdk.com/tutorial/zh/back.html#pay-by-prime-api
        }
      );
      return;
    }
    return console.warn('TPDirect sdk not initialized');
  };

  useEffect(() => {
    setTimeout(() => {
      handleSDKSetup();
      handleCardSetup();
      handleUpdateListener();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tappayEl: (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `.tpfield {
        height: 40px;
        width: 300px;
        border: 1px solid gray;
        border-radius: 0.25em;
        margin: 0.25rem 0 0.75rem;
        padding: 0.5em 0.75em;
        font-size: 0.75rem;
        ${styleToString(style?.tpfield || {})}
        }
        .tpLabel {
          text-align: left;
          font-size: 0.8em;
          ${styleToString(style?.tplabel || {})}
        }
        `,
          }}
        ></style>
        <div>
          <div className="tpLabel">
            信用卡卡號 (支援 Visa, Master Card, JCB, AMEX)
          </div>
          <div className="tpfield" id="card-number"></div>
          <div className="tpLabel">卡片到期日</div>
          <div className="tpfield" id="card-expiration-date"></div>
          <div className="tpLabel">卡片後三碼</div>
          <div className="tpfield" id="card-ccv"></div>
        </div>
      </>
    ),
    onSubmit,
    canGetPrime,
  };
}

export default useTappay;
