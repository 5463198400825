import { extendTheme } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { rgba } from 'polished';

// Component based to expand new styles
import Button from './components/Button';
import Modal from './components/Modal';
import colors from './colors';

const getChakraTheme = () => {
  const { primaryColor } = colors;

  return extendTheme({
    config: {
      initialColorMode: 'light',
    },
    colors: {
      brand: {
        ...colors,
        50: `${rgba(primaryColor, 0.04)}`,
        100: `${rgba(primaryColor, 0.06)}`,
        200: primaryColor,
        300: primaryColor,
        400: primaryColor,
        500: primaryColor,
        600: primaryColor,
        700: primaryColor,
        800: primaryColor,
        900: primaryColor,
      },
      red: {
        50: `${rgba('#EF4444', 0.04)}`,
        100: `${rgba('#EF4444', 0.06)}`,
        500: '#EF4444',
      },
      // override chakra's defined colors if need
      gray: {
        10: '#F7F7F7',
        50: '#F0F1F2',
        100: '#E1E3E6',
        150: '#D3D5D9',
        200: '#C4C7CC',
        300: '#A6AAB3',
        400: '#898E99',
        500: '#6B7280',
        600: '#565B66',
        700: '#40444D',
        800: '#2B2E33',
        850: '#202226',
        900: '#15171A',
      },
      orange: {
        500: '#F97316',
      },
    },
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          // according to design, so override chakra's default colors
          bg: mode('#fff', 'gray.900')(props),
        },
        '*, *::before, &::after': {
          borderColor: mode('gray.100', 'gray.600')(props),
        },
      }),
    },
    components: {
      Button,
      Modal,
    },
  });
};

export { getChakraTheme };
