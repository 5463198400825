type postType = {
  url: string;
  body: Record<string, unknown>;
  headers?: any;
  onSuccess: (response: any) => void;
  onError: (error: string) => void;
};

async function post({ url, body, headers = {}, onSuccess, onError }: postType) {
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  });
  if (result.status === 200) {
    const response = await result.json();
    onSuccess(response);
    return;
  }
  const text = await result.text();
  onError(text);
}

type asyncPostType = {
  url: string;
  body: Record<string, unknown>;
  headers?: any;
};

function asyncPost({ url, body, headers = {} }: asyncPostType) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    })
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        }
        throw result;
      })
      .then((data) => resolve(data))
      .catch(async (err: Response) => {
        try {
          const body = await err.json();
          // Handle graphql error
          if (
            body.response &&
            body.response.errors &&
            body.response.errors[0]
          ) {
            reject(body.response.errors[0].message);
            return;
          }

          // Handle firebase auth error
          if (body.code === 'auth/id-token-expired') {
            // reject(body.message);
            reject(`Login session expired, please reload the page`);
            return;
          }

          // Handle api error
          if (body.message) {
            reject(body.message);
            return;
          }

          reject(body);
        } catch (parseErr) {
          reject(parseErr);
        }
      });
  });
}

export { post, asyncPost };
