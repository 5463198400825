const DEFAULT_BLUE = '#007bff';

export type BrandColors = {
  primaryColor: string;
  secondColor?: string; // from new movement theme
  titleColor: string;
  colorMode: 'light' | 'dark';
};

export type LegacyBrandColors = {
  primaryColor: string;

  navbarColor: string;
  navbarTextColor: string;

  textColor: string;
  backgroundColor: string;
};

const colors: BrandColors = {
  primaryColor: DEFAULT_BLUE,
  titleColor: '#000',
  colorMode: 'light',
};

export { DEFAULT_BLUE };
export default colors;
