import {
  Box,
  BoxProps,
  HStack,
  HTMLChakraProps,
  Stack,
  Text,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

function BannerButton(props: HTMLChakraProps<'button'>) {
  return (
    <chakra.button
      px="4"
      py="1.5"
      textAlign="center"
      borderWidth="1px"
      borderColor="whiteAlpha.400"
      fontWeight="medium"
      rounded="base"
      _hover={{ bg: 'whiteAlpha.200' }}
      {...props}
    />
  );
}

type Props = {
  title: string;
  text: string;
  icon?: ReactNode;
  wrapperProps?: BoxProps;
  onClick?: () => void;
};

function Banner({ title, text, icon, wrapperProps, onClick }: Props) {
  return (
    <Box as="section" pt="8" pb="12" {...wrapperProps}>
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
        py="3"
        px={{ base: '3', md: '6', lg: '8' }}
        color="white"
        bg={useColorModeValue('blue.600', 'blue.400')}
      >
        <HStack spacing="3">
          {icon}
          <Text fontWeight="medium" marginEnd="2">
            {title}
          </Text>
        </HStack>
        <BannerButton
          w={{ base: 'full', sm: 'auto' }}
          flexShrink={0}
          onClick={onClick}
        >
          {text}
        </BannerButton>
      </Stack>
    </Box>
  );
}

export default Banner;
