import firebase from 'firebase';
import { AvatarConfig } from 'react-nice-avatar';
import { createContext } from 'react';

import { BusinessPayout, IndividualPayout } from '@lib/types/payout';

import { ChatRoomSetting, UserTypeFromFirebase } from '../lib/types';
import {
  DataMessageCategory,
  DataMessagePayload,
} from '../lib/types/dataMessage';

export { ChimeContext } from './ChimeContext';

export type alertMessageType = {
  type?: 'info' | 'success' | 'warning' | 'error' | undefined;
  message: string;
};

export const FirebaseContext = createContext<{
  firebase: typeof firebase | null;
}>({
  firebase: null,
});

export const AccountContext = createContext<{
  uid?: string;
  idToken?: string;
  user: Partial<
    {
      email: string;
      name: string;
      avatar: AvatarConfig;
      isLoaded: boolean;
      isAnonymous: boolean;
    } & UserTypeFromFirebase &
      ChatRoomSetting & {
        ctas: DataMessagePayload[DataMessageCategory.CTA][];
        forms: any[];
        polls: any[];
      } & { payoutSettings: IndividualPayout | BusinessPayout }
  >;
  isSU: boolean;
  handleRefetchUid: (uid: string) => Promise<boolean> | void;
  alertMessage?: alertMessageType;
  userHash?: string;
}>({
  uid: undefined,
  idToken: undefined,
  user: {},
  isSU: false,
  handleRefetchUid: (uid) => undefined,
  alertMessage: undefined,
  userHash: undefined,
});
