import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

/**
 * ref:
 * [component parts]
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/anatomy/src/index.ts
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts
 */

const Modal: ComponentMultiStyleConfig = {
  parts: ['dialog'],

  baseStyle: (props) => ({
    header: {
      fontWeight: 'bold',
      fontSize: 'xl',
      color: props.colorMode === 'light' ? 'gray.800' : '#fff',
    },
    dialog: {
      borderRadius: '20px',
      bg: props.colorMode === 'light' ? '#fff' : 'gray.850',
      color: props.colorMode === 'light' ? 'gray.600' : 'gray.200',
    },
  }),
};

export default Modal;
