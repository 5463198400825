import { createContext } from 'react';

import { GeneralRoomProps } from '@lib/types';
import { UseRoleReturnType } from '@lib/hooks/useRole';

export const ChimeContext = createContext<
  {
    roster: UseRoleReturnType['rosterData'];
    me: UseRoleReturnType['me'];
    isEmbedded: boolean;
  } & GeneralRoomProps
>({
  roster: {},
  me: undefined,
  meetingId: '',
  event: {
    id: '',
    name: '',
    agendas: [],
    startedAt: 0,
  },
  isEmbedded: false,
});
