import useSound from 'use-sound';
import { ReactNode, createContext, useContext, useMemo } from 'react';

export enum ThemeMode {
  Dark = 'dark',
  Light = 'light',
}

type SystemSoundContextValue = {
  playNotiSound: () => void;
  playWarningSound: () => void;
  playSuccessSound: () => void;
  playInfoSound: () => void;
};

const SystemSoundContext = createContext<SystemSoundContextValue | null>(null);

type Props = {
  children: ReactNode;
};

function SystemSoundProvider({ children }: Props) {
  const [playNotiSound] = useSound('/sounds/ES_Multimedia_793.mp3', {
    volume: 0.5,
  });
  const [playWarningSound] = useSound('/sounds/ES_Multimedia_681.mp3', {
    volume: 0.5,
  });
  const [playSuccessSound] = useSound('/sounds/ES_Multimedia_980.mp3', {
    volume: 0.5,
  });
  const [playInfoSound] = useSound('/sounds/ES_Multimedia_683.mp3', {
    volume: 0.5,
  });

  const contextValue = useMemo(
    () => ({
      playNotiSound,
      playInfoSound,
      playWarningSound,
      playSuccessSound,
    }),
    [playInfoSound, playNotiSound, playSuccessSound, playWarningSound]
  );

  return (
    <SystemSoundContext.Provider value={contextValue}>
      {children}
    </SystemSoundContext.Provider>
  );
}

export const useSystemSound = () => {
  const contextValue = useContext(SystemSoundContext);

  if (!contextValue) {
    throw new Error('SystemSoundProvider must be initialized first');
  }

  return contextValue;
};

export default SystemSoundProvider;
