import { CTAType } from './cta';

import { ReactionType, Role } from '.';

export enum DataMessageCategory {
  StopAllInput = 'stopAllInput',
  UpdateRole = 'updateRole',
  ProposeRoleChange = 'proposeRoleChange',
  DeclineProposeRoleChange = 'declineProposeRoleChange',
  CTA = 'cta',
  HandRaise = 'handRaise',
  HandDown = 'handDown',
  Reaction = 'reaction',
  SocialProof = 'socialProof',
}

export type DataMessagePayload = {
  [DataMessageCategory.StopAllInput]: {
    uid: string;
  };
  [DataMessageCategory.UpdateRole]: {
    uid: string;
    role: Role;
  };
  [DataMessageCategory.ProposeRoleChange]: {
    uid: string;
    role: Role.Speaker | Role.Moderator;
  };
  [DataMessageCategory.DeclineProposeRoleChange]: {
    uid: string;
  };
  [DataMessageCategory.CTA]: CTAType;
  [DataMessageCategory.HandRaise]: {
    name: string;
  };
  [DataMessageCategory.HandDown]: {
    name: string;
  };
  [DataMessageCategory.Reaction]: ReactionType;
  [DataMessageCategory.SocialProof]: {
    timestamp: number;
  };
};
