import { MeetingReaction } from '../../constants';

export * from './brand';
export * from './chime';
export * from './dataMessage';
export * from './event';
export * from './raiseHand';
export * from './user';
export * from './cta';
export * from './eventStat';
export * from './redeemCode';
export * from './meeting';

export type ReactionType = {
  type: keyof typeof MeetingReaction;
  uid: string;
  timestamp: number;
};

export enum Role {
  Host = 'host',
  Moderator = 'moderator',
  Speaker = 'speaker',
  Audience = 'audience',
}

export enum Permission {
  Checking = 'CHECKING',
  Unset = 'UNSET',
  Granted = 'GRANTED',
  Denied = 'DENIED',
}

export enum SettingsTab {
  BasicInfo = 0,
  BrandPage = 1,
  GeneralRedeemCodes = 2,
  PaymentInfo = 3,
}

export type PostMessagePayload = {
  source: 'Teachify' | 'Live';
  message: string;
};
