import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react';
import { FaTwitter } from 'react-icons/fa';
import * as React from 'react';

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    {/* <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin fontSize="20px" />} /> */}
    {/* <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="20px" />} /> */}
    <IconButton
      as="a"
      href="https://twitter.com/kaikhq"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
    />
  </ButtonGroup>
);
