import Cookies from 'js-cookie';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { DefaultSeo } from 'next-seo';
import { IntercomProvider } from 'react-use-intercom';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { genConfig } from 'react-nice-avatar';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';

import 'react-datepicker/dist/react-datepicker.css';

import { AccountContext, FirebaseContext } from '../contexts';
import { useScript } from '../lib/hooks/useTappay';
import '../lib/i18n';
import '../styles/globals.scss';
// import your default seo configuration
import SEO from '../next-seo.config';
import SystemSoundProvider from '../providers/SystemSoundProvider';
import AudioVideoPermissionProvider from '../providers/AudioVideoPermissionProvider';
import firebase from '../lib/firebase';
import mixpanel from '../lib/mixpanel';
import { getChakraTheme } from '../lib/theme';

import Error from './_error';

const INTERCOM_APP_ID = 'xmi31vy2';

const theme = getChakraTheme();

const filterDeletedCtas = (ctas) => {
  if (!ctas) {
    return;
  }

  const clonedCtas = {
    ...ctas,
  };

  Object.entries(ctas).map(([key, cta]) => {
    if (cta?.deletedAt) {
      delete clonedCtas[key];
    }
  });

  return clonedCtas;
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [uid, setUid] = useState(null);
  const [userHash, setUserHash] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [isSU, setIsSU] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isLoaded: false,
  });
  const [alertMessage, setAlertMessage] = useState(null);

  const { t } = useTranslation();

  useScript('https://js.tappaysdk.com/tpdirect/v5.5.0', () => {
    return !!document.querySelector(
      'script[src~="https://js.tappaysdk.com/tpdirect/v5.5.0"]'
    );
  });

  const fetchUserData = useCallback(async (uid) => {
    if (!uid) {
      return;
    }

    mixpanel.identify(uid);

    firebase
      .database()
      .ref(`/${process.env.NODE_ENV}/user/${uid}`)
      .on('value', (snapshot) => {
        const user = snapshot.val();
        if (!user) return;

        if (!user.avatar) {
          const niceAvatarConfig = genConfig();
          firebase
            .database()
            .ref(`/${process.env.NODE_ENV}/user/${uid}/avatar`)
            .set(niceAvatarConfig);
          return;
        }

        if (!user.enableBrand) {
          firebase
            .database()
            .ref(`/${process.env.NODE_ENV}/user/${uid}/enableBrand`)
            .set(true);
          return;
        }

        const currentUser = firebase.auth().currentUser;
        setUser({
          ...user,
          ctas: filterDeletedCtas(user.ctas),
          isLoaded: true,
          isAnonymous: currentUser.isAnonymous,
        });
        if (currentUser) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
              setIdToken(idToken);
            })
            .catch(function () {
              window.location.href = `/login?redirect=${window.location.pathname}`;
            });
        }
      });
  }, []);

  const handleRefetchUid = useCallback(
    async (uid) => {
      setUid(uid);
      await fetchUserData(uid);
    },
    [fetchUserData]
  );

  const monitorAuthStateChanged = useCallback(async () => {
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        const idToken = await user.getIdToken();
        setUid(user.uid);
        setIdToken(idToken);
      } else {
        setUid('');
        setIdToken('');
      }
    });
  }, []);

  useEffect(() => {
    monitorAuthStateChanged();

    const legacyCookie = Cookies.get('_uid');
    if (legacyCookie) {
      setAlertMessage({
        type: 'warning',
        message: t('Session expired, please login again'),
      });
      Cookies.remove('_uid');
      const pathname = window.location.pathname;
      window.location.href = `/login?redirect=${pathname}`;
      return;
    }

    const isSU = parseInt(Cookies.get('_isSU') || '', 10);
    setIsSU(isSU);
  }, [fetchUserData, monitorAuthStateChanged, t]);

  useEffect(() => {
    const generateUserHash = async () => {
      if (idToken && !userHash) {
        const result = await fetch('/api/generateUserHash', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            id_token: idToken,
          },
        });

        if (result.status === 200) {
          const response = await result.json();
          const { hash } = response;
          if (hash !== '') {
            setUserHash(hash);
          }
        }
      }
    };
    generateUserHash();
  }, [idToken, userHash]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const logEvent = (url) => {
        firebase.analytics().setCurrentScreen(url);
        firebase.analytics().logEvent('screen_view');
      };

      router.events.on('routeChangeComplete', logEvent);
      logEvent(window.location.pathname);

      return () => {
        router.events.off('routeChangeComplete', logEvent);
      };
    }
  }, [router.events]);

  useEffect(() => {
    fetchUserData(uid);
  }, [fetchUserData, uid]);

  const refreshUserIdToken = useCallback(async () => {
    if (firebase) {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        setIdToken(idToken);
      }
    }
  }, []);

  useEffect(() => {
    if (!uid) {
      return;
    }

    // update firebase idToken every 30 minutes
    const intervalID = window.setInterval(() => {
      refreshUserIdToken();
    }, 30 * 60 * 1000);

    return () => {
      window.clearInterval(intervalID);
    };
  }, [refreshUserIdToken, uid]);

  // ref: https://gist.github.com/blakazulu/67f7391120c813095ca47be76a733a69
  useEffect(() => {
    const style =
      'font-size: 16px;' +
      'background: #67b26f; /* fallback for old browsers */' +
      'background: -webkit-linear-gradient(to right, #67b26f, #4ca2cd); /* Chrome 10-25, Safari 5.1-6 */' +
      'background: linear-gradient(to right, #67b26f, #4ca2cd); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */' +
      'color: white;' +
      'text-align: center;' +
      'padding: 10px 15px;' +
      'width: 100%;' +
      'border-radius: 20px;';

    const text =
      '%cCongrats! You found the treasure😎. Join our beta program. https://nutshell.live/signup?ref=console';

    console.log(text, style);
  }, []);

  return (
    <AudioVideoPermissionProvider>
      <FirebaseContext.Provider value={{ firebase: firebase }}>
        <AccountContext.Provider
          value={{
            uid,
            user,
            handleRefetchUid,
            isSU,
            alertMessage,
            userHash,
            idToken,
          }}
        >
          <SystemSoundProvider>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <ChakraProvider theme={theme}>
                <Sentry.ErrorBoundary fallback={Error}>
                  <DefaultSeo {...SEO} />
                  <Component {...pageProps} />
                </Sentry.ErrorBoundary>
              </ChakraProvider>
            </IntercomProvider>
          </SystemSoundProvider>
        </AccountContext.Provider>
      </FirebaseContext.Provider>
    </AudioVideoPermissionProvider>
  );
}

export default appWithTranslation(MyApp);
