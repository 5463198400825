import type { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const Button: ComponentStyleConfig = {
  variants: {
    solid: {
      color: '#fff',
      '&[disabled], &.disabled, &[disabled]:hover, &.disabled:hover': {
        backgroundColor: 'brand.primaryColor',
        opacity: 0.4,
      },
    },
    'outline-gray-500': (props) => ({
      border: '1px solid',
      borderColor: mode('gray.500', 'gray.600')(props),
      color: mode('gray.500', 'gray.600')(props),
      '&:hover': {
        bg: mode('gray.50', 'whiteAlpha.200')(props),
      },
    }),
    'outline-red-500': (props) => ({
      border: '1px solid',
      borderColor: 'red.500',
      color: 'red.500',
      '&:hover': {
        bg: mode('red.50', '#E53E3E30')(props), // red.500(#E53E3E) with alpha 30
      },
    }),
  },
};

export default Button;
